import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Swal from 'sweetalert2'

export default function List() {

    const [products, setProducts] = useState([])

    useEffect(()=>{
        fetchProducts() 
    },[])

    
  let axiosConfig = {
    headers : {
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
        'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDhjN2FjYTgyODlkNDk2YzE1ODE2NGE1ZmVjZjBlYmNlMGJjZTk3NTAyNjQ0MDhiZDYxYmYxNDQzODBmYWE3MmE3OTYwOGQ1MWM3NmYzMDkiLCJpYXQiOjE2Nzc0MDkwOTYuMDY5MjUsIm5iZiI6MTY3NzQwOTA5Ni4wNjkyNTUsImV4cCI6MTcwODk0NTA5NS42NDcwNTksInN1YiI6IjEiLCJzY29wZXMiOltdfQ.LkyAh4z7iLcReizY2QFT1tUVNqjc5jbyW0SwaCEKOg4AT3gQF1B3qMkyKt5tcxJH7H8KzJRjkpe0zNJYZysQgts44IwxJkCnsKVzyiAWPr0qrI-AT0uoykAMVgbzQoAaEXJdwzF3XqyXZY-fk2m9TXJYzbZkQY9Oy8aQgsqoYKiSxryWCHYm9jpeWGOKUTHj5hIzZ2HvpLf9_JgDXtlzw5GC8EcjLel56GnJYWQ75z_OBNwBfkK60Wj5t_uJ7OZo0o9r2zZ3Zzt3ZVXgM7shm-MmVyDqeuVWbiIT5WWUsMwa8vswXEvhlH1C6z1W-c6uMa3lstQxxDJIC6yvL_-1xf7Nhk-ILYGX-doZ3NUpMXwEJ9nAC7_BSo9GCXWW9ShD4VbFh8aha5me6Qmgjrh_TgmfwJFajWbvksS2lKTlFZ5fspsVQ-NXpt1eQ74t_q205u89aVI-cmHm-uo9CmX4nZeWRS41eVTtvVmDNkIbAqsMjjO0z07WqIZBZr16BY1qUcJNUKEzRC0-2ywqJn3xi8MIrg9phgOh2lU5VHBNLIiLd3-JJPJzfqXCZJaY35YIQQws4sV6KukMzLADDhD6aMXoPXVitl2H10XCslqFwFplkLWlOh3v4uka8ve4G_KuJLUuLhTRlQoHp5l9PciUxV219nXRF9d1MP-Io7RXpVQ',
    }
}

    const fetchProducts = async () => {
        await axios.get(`https://www.laravel.blupace.net/api/products`,axiosConfig).then(({data})=>{
            setProducts(data)
        }).catch(({response:{data}})=>{
            Swal.fire({
                text:data.message,
                icon:"error"
            })
          })
    }

    const deleteProduct = async (id) => {
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }

          await axios.delete(`https://www.laravel.blupace.net/api/products/${id}`,axiosConfig).then(({data})=>{
            Swal.fire({
                icon:"success",
                text:data.message
            })
            fetchProducts()
          }).catch(({response:{data}})=>{
            Swal.fire({
                text:data.message,
                icon:"error"
            })
          })
    }

    return (
      <div className="container">
          <div className="row">
            <div className='col-12'>
                <Link className='btn btn-primary mb-2 float-end' to={"/product/create"}>
                    Create Product
                </Link>
            </div>
            <div className="col-12">
                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered mb-0 text-center">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Image</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products.length > 0 && (
                                        products.map((row, key)=>(
                                            <tr key={key}>
                                                <td>{row.title}</td>
                                                <td>{row.description}</td>
                                                <td>
                                                    <img alt={row.title} width="50px" src={`https://react.blupace.net/storage/product/image/${row.image}`} />
                                                </td>
                                                <td>
                                                    <Link to={`/product/edit/${row.id}`} className='btn btn-success me-2'>
                                                        Edit
                                                    </Link>
                                                    <Button variant="danger" onClick={()=>deleteProduct(row.id)}>
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
      </div>
    )
}