import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export default function EditUser() {
  const navigate = useNavigate();

  const { id } = useParams()

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState(null)
  const [validationError,setValidationError] = useState({})

  useEffect(()=>{
    fetchProduct()
  },[])

  
  let axiosConfig = {
    headers : {
        'Content-Type' : 'multipart/form-data',
        'Accept' : 'application/json',
        'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDhjN2FjYTgyODlkNDk2YzE1ODE2NGE1ZmVjZjBlYmNlMGJjZTk3NTAyNjQ0MDhiZDYxYmYxNDQzODBmYWE3MmE3OTYwOGQ1MWM3NmYzMDkiLCJpYXQiOjE2Nzc0MDkwOTYuMDY5MjUsIm5iZiI6MTY3NzQwOTA5Ni4wNjkyNTUsImV4cCI6MTcwODk0NTA5NS42NDcwNTksInN1YiI6IjEiLCJzY29wZXMiOltdfQ.LkyAh4z7iLcReizY2QFT1tUVNqjc5jbyW0SwaCEKOg4AT3gQF1B3qMkyKt5tcxJH7H8KzJRjkpe0zNJYZysQgts44IwxJkCnsKVzyiAWPr0qrI-AT0uoykAMVgbzQoAaEXJdwzF3XqyXZY-fk2m9TXJYzbZkQY9Oy8aQgsqoYKiSxryWCHYm9jpeWGOKUTHj5hIzZ2HvpLf9_JgDXtlzw5GC8EcjLel56GnJYWQ75z_OBNwBfkK60Wj5t_uJ7OZo0o9r2zZ3Zzt3ZVXgM7shm-MmVyDqeuVWbiIT5WWUsMwa8vswXEvhlH1C6z1W-c6uMa3lstQxxDJIC6yvL_-1xf7Nhk-ILYGX-doZ3NUpMXwEJ9nAC7_BSo9GCXWW9ShD4VbFh8aha5me6Qmgjrh_TgmfwJFajWbvksS2lKTlFZ5fspsVQ-NXpt1eQ74t_q205u89aVI-cmHm-uo9CmX4nZeWRS41eVTtvVmDNkIbAqsMjjO0z07WqIZBZr16BY1qUcJNUKEzRC0-2ywqJn3xi8MIrg9phgOh2lU5VHBNLIiLd3-JJPJzfqXCZJaY35YIQQws4sV6KukMzLADDhD6aMXoPXVitl2H10XCslqFwFplkLWlOh3v4uka8ve4G_KuJLUuLhTRlQoHp5l9PciUxV219nXRF9d1MP-Io7RXpVQ',
    }
}

  const fetchProduct = async () => {
    await axios.get(`https://www.laravel.blupace.net/api/products/${id}`,axiosConfig).then(({data})=>{
      const { title, description } = data.product
      setTitle(title)
      setDescription(description)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }

  const changeHandler = (event) => {
		setImage(event.target.files[0]);
	};

  const updateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('_method', 'PATCH');
    formData.append('title', title)
    formData.append('description', description)
    if(image!==null){
      formData.append('image', image)
    }
//console.log(formData);
    await axios.post(`https://www.laravel.blupace.net/api/products/${id}`, formData,axiosConfig).then(({data})=>{
      Swal.fire({
        icon:"success",
        text:data.message
      })
      navigate("/")
    }).catch(({response})=>{
      if(response.status===422){
        setValidationError(response.data.errors)
      }else{
        Swal.fire({
          text:response.data.message,
          icon:"error"
        })
      }
    })
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Update Product</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>   
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={updateProduct}>
                  <Row> 
                      <Col>
                        <Form.Group controlId="Name">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={title} onChange={(event)=>{
                              setTitle(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>  
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} value={description} onChange={(event)=>{
                              setDescription(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="Image" className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <Form.Control name="image" type="file" onChange={changeHandler} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button variant="primary" className="mt-2" size="lg" block="block" type="submit">
                    Update
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}